<template>
  <BaseListPage locale-section="pages.materialTypes" route="materialType">
    <MaterialTypesList />
  </BaseListPage>
</template>

<script>
export default {
  name: "MaterialTypesPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    MaterialTypesList: () => import("./MaterialTypesList")
  }
};
</script>
